import { baseApiURL } from 'config';

export function postLogin(credentials) {
  return fetch(`${baseApiURL}/api/users/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  }).then(response => {
    if (response.status === 400) return Promise.reject('User not found');
    return response.json();
  });
}

export function postCreateAccount(credentials) {
  return fetch(`${baseApiURL}/api/users/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  }).then(response => {
    if (response.status === 400) return Promise.reject('User not found');
    return response.json();
  });
}

export function getIsUsernameAvailable(username) {
  return fetch(
    `${baseApiURL}/api/user/username/${username}?returnBoolean=true`,
  ).then(response => !!(response.status === 200));
}

export function postGoogleLogin(googleCredentials) {
  return fetch(`${baseApiURL}/api/users/login/google`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(googleCredentials),
  }).then(response => {
    if (response.status === 400) return Promise.reject('User not found');
    return response.json();
  });
}

export function postVerifyToken(token) {
  return fetch(`${baseApiURL}/api/users/verify`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token }),
  }).then(response => {
    if (response.status === 400) return Promise.reject('User not found');
    return response.json();
  });
}

export function getUser(username) {
  return fetch(`${baseApiURL}/api/user/username/${username}`).then(response => {
    if (response.status === 400) return Promise.reject('User not found');
    return response.json();
  });
}

export function postForgotPassword(email: string) {
  return fetch(`${baseApiURL}/api/users/forgot`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });
}
export function postUpdatePassword(data: { token: string; password: string }) {
  return fetch(`${baseApiURL}/api/users/pw`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(response => {
    if (response.status !== 200) return Promise.reject();
    return response.json();
  });
}
