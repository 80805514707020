import { selector, selectorFamily } from 'recoil';

import { baseApiURL } from '../config';
import {
  entryAtom,
  exclusionsByListIdAtom,
  listAtom,
  listIdsAtom,
  matchupQueueIndexAtom,
} from './atoms';

// QUERIES
// queries should not set state. fetch data in the component and
// set the atom state with useRecoilCallback

// top-level query, only used on the home page & app-reload
export const allListDataQuery = selector({
  key: 'allListData',
  get: () => {
    const route = '/api/lists';
    return fetch(`${baseApiURL}${route}`).then(r => r.json());
  },
});

export const selectMatchupQueue = selectorFamily({
  key: 'selectMatchupQueue',
  get: listId => () => {
    const route = `/api/matchups/queue/${String(listId)}`;
    return fetch(`${baseApiURL}${route}`).then(r => {
      if (r.status !== 200) {
        return [];
      }
      return r.json();
    });
  },
});

// DERIVED DATA
/**
 * given a listID, return an map of entryID: entry
 */
export const selectEntyIdMapForList = selectorFamily({
  key: 'entriesByListId',
  get:
    listId =>
    ({ get }) => {
      const listData = get(listAtom(listId));
      const entryIdMap = {};
      listData.entries.forEach(entryId => {
        entryIdMap[entryId] = get(entryAtom(entryId));
      });
      return entryIdMap;
    },
});

export const selectListIdMap = selector({
  key: 'listIdMap',
  get: ({ get }) => {
    const listIds = get(listIdsAtom);
    const listIdMap = {};
    listIds.forEach(id => {
      listIdMap[id] = get(listAtom(id));
    });
    return listIdMap;
  },
});

// return an idMap so components don't need to loop over arrays
export const exclusionMapByListIdAtom = selectorFamily({
  key: 'exclusionMap',
  get:
    listId =>
    ({ get }) => {
      const exclusionsArray = get(exclusionsByListIdAtom(listId));
      const idMap = {} as any;
      exclusionsArray.forEach(id => {
        idMap[id] = true;
      });
      return idMap;
    },
});

/**
 * for a listId, return a data structure compiling the matchup atoms
 */
// todo - check performance here, maybe need to pull atoms individually
// apply filters from exclusions
export const selectQueueData = selectorFamily({
  key: 'queueData',
  get:
    listId =>
    ({ get }) => {
      const index = get(matchupQueueIndexAtom(listId));
      const queue = get(selectMatchupQueue(listId));
      const exclusions = get(exclusionsByListIdAtom(listId));

      return {
        queue: (queue || []).filter(matchupPair => {
          // if the matchupPair includes one of the items in `exclusions` return false
          return !exclusions.some(exclusionId => {
            const matchupPairIncludesExclusion =
              matchupPair.indexOf(exclusionId) >= 0;
            return matchupPairIncludesExclusion;
          });
        }),
        index,
      };
    },
});

export const rankingsSelector = selectorFamily({
  key: 'rankings',
  get:
    listId =>
    ({ get }) => {
      const list = get(listAtom(listId));
      const entryIdMap = get(selectEntyIdMapForList(listId));
      // sort list.entries by the score on each entry
      return [...list.entries].sort((a: string, b: string): number => {
        if (entryIdMap[a].score < entryIdMap[b].score) return 1;
        if (entryIdMap[a].score > entryIdMap[b].score) return -1;
        return 0;
      });
    },
});
