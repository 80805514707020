import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import CssBaseline from '@mui/material/CssBaseline';

import { darkModeAtom } from 'recoilStore/ui';

function CageTheme({ children }) {
  // https://mui.com/customization/theming/
  const darkMode = useRecoilValue(darkModeAtom);

  const themeOptions = {
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
    typography: {
      fontFamily: 'Roboto Mono',
      h1: {
        fontSize: '4.5rem',
      },
      h2: {
        fontSize: '2.5rem',
      },
      h3: {
        fontSize: '2rem',
      },
    },
    components: {
      MuiCard: {
        defaultProps: {
          variant: 'outlined',
        },
        // styleOverrides: {
        //   root: {
        //     border: '1px solid purple',
        //   },
        // },
      },
    },
  };
  const theme = createTheme(themeOptions as any) as any;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

// wrapping ThemeProvider so I can pull darkMode state value from recoil
export default CageTheme;
