import {
  Alert,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Typography,
} from '@mui/material';
import Cookies from 'js-cookie';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  postCreateAccount,
  postForgotPassword,
  postGoogleLogin,
  postLogin,
} from 'api/auth';
import { currentUserAtom } from 'recoilStore/atoms';
import SignInWithGoogle from 'components/SignInWithGoogle';
import { loginRedirectAtom } from 'recoilStore/ui';

function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [isPendingPwReset, setIsPendingPwReset] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const setCurrentUser = useSetRecoilState(currentUserAtom);
  const loginRedirect = useRecoilValue(loginRedirectAtom);

  function onGoogleSuccess(googleSuccessData) {
    setError('');

    const {
      profileObj: { email, givenName, familyName, googleId, imageUrl },
      accessToken,
    } = googleSuccessData;
    const googleLoginData = {
      email,
      givenName,
      familyName,
      googleId,
      accessToken,
      imageUrl,
      username: googleId,
    };
    postGoogleLogin(googleLoginData)
      .then(user => {
        Cookies.set('authToken', user.token);
        setCurrentUser(user);
        navigate(loginRedirect);
      })
      .catch(e => {
        setError(e);
      });
  }

  function onGoogleFail(data) {
    const isHandlingError = false;
    if (isHandlingError) {
      // todo - handle various error cases
      console.log(data);
    }
    setError('There was an error signing in with Google');
  }

  function submitPasswordLogin() {
    setError('');
    postLogin({ username, password })
      .then(user => {
        Cookies.set('authToken', user.token);
        setCurrentUser(user);
        navigate(loginRedirect);
      })
      .catch(e => {
        setError(e);
      });
  }

  function submitCreateAccount() {
    setError('');
    postCreateAccount({ username, password, email })
      .then(user => {
        Cookies.set('authToken', user.token);
        setCurrentUser(user);
        navigate('/');
      })
      .catch(e => {
        setError(e);
      });
  }

  function submitForgotPassword() {
    setError('');
    postForgotPassword(username)
      .then(() => {
        setIsPendingPwReset(true);
      })
      .catch(() => {
        setError('Sorry, something went wrong');
      });
  }

  const [isSignIn, setIsSignIn] = useState(true);
  function toggleSignInSignUp() {
    setIsSignIn(!isSignIn);
  }
  return (
    <Container>
      <Card>
        <Grid
          container
          sx={{
            width: '450px',
          }}
          mt={0}
          rowSpacing={2}
          mx="auto"
          py={4}
        >
          <Grid item xs={12}>
            <Typography variant="h1" fontSize={34} mb={4}>
              {isForgotPassword
                ? 'Reset Password'
                : isSignIn
                ? 'Sign In'
                : 'Create Account'}
            </Typography>

            <SignInWithGoogle
              onSuccess={onGoogleSuccess}
              onFail={onGoogleFail}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="center">or</Divider>
          </Grid>
          {isForgotPassword ? (
            <>
              <Grid item xs={12}>
                <InputLabel>Email</InputLabel>
                <Input
                  sx={{ width: '100%' }}
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  key={'resetpw'}
                  sx={{ width: '100%' }}
                  variant="contained"
                  onClick={submitForgotPassword}
                  disabled={username.indexOf('@') < 1}
                >
                  Reset password
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  key={'backtologin'}
                  sx={{ width: '100%' }}
                  variant="text"
                  onClick={() => setIsForgotPassword(false)}
                >
                  Back to log in
                </Button>
              </Grid>
              {isPendingPwReset && (
                <Grid item xs={12}>
                  <Alert severity="success">
                    Check your email to reset your password
                  </Alert>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <InputLabel>
                  {isSignIn ? 'Username or Email' : 'Username'}
                </InputLabel>
                <Input
                  sx={{ width: '100%' }}
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                />
              </Grid>
              {!isSignIn && (
                <Grid item xs={12}>
                  <InputLabel>Email</InputLabel>
                  <Input
                    sx={{ width: '100%' }}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <InputLabel>
                  Password
                  <IconButton
                    key={'iconbtn'}
                    tabIndex={-1}
                    onClick={e => {
                      e.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <VisibilityOff /> : <VisibilityIcon />}
                  </IconButton>
                </InputLabel>
                <Input
                  sx={{ width: '100%' }}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </Grid>
              {!isSignIn && (
                <Grid item xs={12}>
                  <InputLabel>Confirm Password</InputLabel>
                  <Input
                    sx={{ width: '100%' }}
                    type={showPassword ? 'text' : 'password'}
                    value={passwordConfirm}
                    onChange={e => setPasswordConfirm(e.target.value)}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  key={isSignIn ? 'login-signin' : 'createaccount'}
                  size="large"
                  sx={{ width: '100%' }}
                  variant="contained"
                  onClick={isSignIn ? submitPasswordLogin : submitCreateAccount}
                  disabled={
                    !isSignIn &&
                    (password !== passwordConfirm ||
                      !email ||
                      !username ||
                      !password)
                  }
                >
                  {isSignIn ? 'Log in' : 'Create account'}
                </Button>
              </Grid>
              <Grid container item xs={12} justifyContent={'space-around'}>
                <Button
                  key={isSignIn ? 'reg' : 'already'}
                  size="small"
                  variant="text"
                  onClick={() => {
                    setError('');
                    toggleSignInSignUp();
                  }}
                >
                  {isSignIn ? 'Register' : 'Already have an account?'}
                </Button>
                {isSignIn && (
                  <Button
                    key="forgot"
                    size="small"
                    variant="text"
                    onClick={() => {
                      setError('');
                      setIsForgotPassword(true);
                    }}
                  >
                    Forgot password
                  </Button>
                )}
              </Grid>
            </>
          )}
          {error && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
        </Grid>
      </Card>
    </Container>
  );
}

export default LoginPage;
