import React from 'react';
import { Grid, Stack, Paper, Typography, Divider } from '@mui/material';
import { useRecoilValue } from 'recoil';
import numeral from 'numeral';

import { rankingsSelector } from 'recoilStore/selectors';
import { entryAtom } from 'recoilStore/atoms';
import { Entry } from 'types';
import EntryImage from './EntryImage';

function EntryGridItem({ entryId, rank }) {
  const entry = useRecoilValue(entryAtom(entryId)) as Entry;
  const { title, image: imageUrl, winCount, lossCount, score } = entry;

  return (
    <Grid xs={3} md={3} item>
      <Paper sx={{ height: '100%', padding: '1rem' }} elevation={2}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
          spacing={1}
        >
          <Stack direction="row" justifyContent="space-between">
            {/* rank */}
            <Typography fontSize={18} variant="body1">
              #{rank}
            </Typography>
            <Typography fontStyle="italic" color="primary.dark">
              {score}
            </Typography>
          </Stack>

          {/* image */}
          <EntryImage width="100%" src={imageUrl} alt={title} />
          {/* title */}
          <Typography fontWeight="bold" variant="subtitle1">
            {title}
          </Typography>
          <Divider />

          {/* stats */}
          <Stack direction="column">
            <Typography variant="overline">
              {winCount && lossCount
                ? numeral(winCount / (winCount + lossCount)).format('0%')
                : '0%'}{' '}
              win rate
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="overline">
                {winCount} {winCount > 1 ? 'wins' : 'win'}
              </Typography>
              <Typography variant="overline">
                {winCount + lossCount}{' '}
                {winCount + lossCount > 1 ? 'matchups' : 'matchup'}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Grid>
  );
}

function RankingsGrid({ listId }) {
  const rankings = useRecoilValue(rankingsSelector(listId));

  return (
    <Grid container spacing={2} p={2}>
      {rankings.map((id, index) => {
        return <EntryGridItem key={id} rank={index + 1} entryId={id} />;
      })}
    </Grid>
  );
}

export default RankingsGrid;
