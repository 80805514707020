import React from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Logo() {
  const navigate = useNavigate();
  return (
    <Typography
      sx={{ cursor: 'pointer', color: 'primary.main' }}
      variant="h3"
      onClick={() => navigate('/')}
    >
      Cagematch
    </Typography>
  );
}

export default Logo;
