import { atom } from 'recoil';

export const loginModalAtom = atom({
  key: 'loginModal',
  default: false,
});

export const loginRedirectAtom = atom({
  key: 'loginRedirect',
  default: '/',
});

// UI
export const darkModeAtom = atom({
  key: 'darkMode',
  default: false,
});
