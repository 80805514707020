import AddBoxIcon from '@mui/icons-material/AddBox';
import {
  Button,
  Container,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Typography,
} from '@mui/material';
import { postCreateList } from 'api/list';
import CreateListTile from 'components/CreateListTile';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { currentUserAtom, entryAtom, listAtom } from 'recoilStore/atoms';
import { PostCreateListResponse } from 'types';

const apiSupportsCriteria = false;
// const defaultEntries = [
//   'A Star is Born',
//   'Ex Machina',
//   'Wolf of Wall Street',
//   'Django Unchained',
// ];
function CreateListPage() {
  const navigate = useNavigate();
  const { id: userId } = useRecoilValue(currentUserAtom);
  const [title, setTitle] = useState('' as string);
  const [description, setDescription] = useState('' as string);
  const [criteria, setCriteria] = useState('' as string);
  const [pendingEntry, setPendingEntry] = useState('' as string);
  const [entries, setEntries] = useState([] as string[]);

  const isValidEntry =
    pendingEntry.length < 1 || entries.indexOf(pendingEntry) === -1;

  function handleAddEntry() {
    setEntries(e => [...e, pendingEntry]);
    setPendingEntry('');
  }
  function handleKeyPress(e) {
    console.log('handle key press');
    if (e.key !== 'Enter') return;
    if (isValidEntry) handleAddEntry();
  }
  const createList = useRecoilCallback(({ set }) => () => {
    if (!userId) return;
    const formattedEntries = entries.map(entryTitle => {
      return {
        title: entryTitle,
      };
    });
    const listData = {
      title,
      entries: formattedEntries,
      userId: String(userId),
      description,
    };
    return postCreateList(listData).then(
      (response: PostCreateListResponse): void => {
        const newListData = {
          ...response.list,
          entries: response.entries.map(entry => entry.id),
          matchupCount: 0,
          voterCount: 0,
        };
        set(listAtom(newListData.id), newListData);
        response.entries.forEach(entry => {
          set(entryAtom(entry.id), { ...entry, winCount: 0, lossCount: 0 });
        });
        navigate(`/list/${newListData.id}`);
      },
    );
  });
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" color="secondary">
            Create a list
          </Typography>
        </Grid>
        {/* Title & Description */}
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={4}>
            <InputLabel>Title</InputLabel>
            <Input
              fullWidth
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={8}>
            <InputLabel>Description</InputLabel>
            <Input
              fullWidth
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Grid>
          {apiSupportsCriteria && (
            <Grid xs={12} item>
              <InputLabel>Criteria</InputLabel>
              <Input
                fullWidth
                value={criteria}
                onChange={e => setCriteria(e.target.value)}
              />
            </Grid>
          )}
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Add candidates to your list</Typography>
          </Grid>
          <Grid item>
            <Input
              value={pendingEntry}
              onChange={e => setPendingEntry(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <IconButton onClick={handleAddEntry} disabled={!isValidEntry}>
              <AddBoxIcon color="primary" />
            </IconButton>
          </Grid>

          {/* display entries */}
          <Grid container item spacing={1}>
            {entries.map(entryTitle => {
              return <CreateListTile key={entryTitle} title={entryTitle} />;
            })}
          </Grid>
        </Grid>
        {entries.length > 2 && (
          <Grid item xs={12}>
            <Button
              fullWidth
              color="primary"
              size="large"
              variant="outlined"
              onClick={createList}
            >
              Start ranking
            </Button>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default CreateListPage;
