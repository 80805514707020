import Elo from 'arpad';
import { baseApiURL } from 'config';
const elo = new Elo({
  default: 32,
});

export function findDiff(winnerScore: number, loserScore: number) {
  const newWinner = elo.newRatingIfWon(winnerScore, loserScore); // 1410
  const newLoser = elo.newRatingIfLost(loserScore, winnerScore); // 1390

  const winnerDiff = newWinner - (winnerScore || 1400); // 10
  const loserDiff = newLoser - (loserScore || 1400); // -10
  return [winnerDiff, loserDiff];
}

export function fetchImageSuggestion(query) {
  return fetch(
    `${baseApiURL}/imdb/cage/image?query=${encodeURIComponent(query)}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  ).then(response => {
    if (response.status !== 200) return Promise.reject();
    return response.json();
  });
}
