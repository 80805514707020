import { QueryClient, QueryClientProvider } from 'react-query';
import { useSetRecoilState } from 'recoil';
import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Cookies from 'js-cookie';
import { CircularProgress } from '@mui/material';

import { postVerifyToken } from 'api/auth';
import { currentUserAtom } from 'recoilStore/atoms';
import { darkModeAtom } from 'recoilStore/ui';
import RecoilStore from 'recoilStore/RecoilStore';
import CageTheme from './CageTheme';
import Header from './Header';

import ListPage from './Pages/ListPage';
import SearchPage from './Pages/SearchPage';
import LoginPage from './Pages/LoginPage';
import UserPage from './Pages/UserPage';
import CreateListPage from './Pages/CreateListPage';
import PasswordResetPage from './Pages/PasswordResetPage';
import AboutPage from './Pages/AboutPage';
import PrivacyPage from './Pages/PrivacyPage';
import HomePage from './Pages/HomePage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [authStatusResolved, setAuthStatusResolved] = useState(false);
  const setCurrentUser = useSetRecoilState(currentUserAtom);
  const setDarkMode = useSetRecoilState(darkModeAtom);

  // APP INITIAL SETUP
  useEffect(() => {
    // dark mode cookie - its a string
    const initialDarkModeString = Cookies.get('darkMode');
    const val = initialDarkModeString === 'true' ? true : false;

    setDarkMode(val);

    // check cookies for a token
    const authToken = Cookies.get('authToken');
    if (!authToken) {
      return setAuthStatusResolved(true);
    }

    postVerifyToken(authToken)
      .then(rawData => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { token, ...data } = rawData;
        setCurrentUser(data);
        return setAuthStatusResolved(true);
      })
      .catch(() => {
        Cookies.remove('authToken');
        return setAuthStatusResolved(true);
      });
  }, []);

  return (
    <CageTheme>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<CircularProgress />}>
            <RecoilStore>
              {!authStatusResolved ? (
                <CircularProgress />
              ) : (
                <>
                  <Header />
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/search" element={<SearchPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/reset" element={<PasswordResetPage />} />
                    <Route path="/create" element={<CreateListPage />} />
                    <Route path="/@/:username" element={<UserPage />} />
                    <Route path="/list/:listId" element={<ListPage />} />
                    {/* <Route path="/legal/terms" element={<TermsPage />} /> */}
                    <Route path="/legal/privacy" element={<PrivacyPage />} />
                  </Routes>
                </>
              )}
            </RecoilStore>
          </Suspense>
        </QueryClientProvider>
      </BrowserRouter>
    </CageTheme>
  );
}

export default App;
