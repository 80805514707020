import React from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { getFeaturedLists } from 'api/list';
import { useQuery } from 'react-query';
import FeaturedListItem from 'components/FeaturedListItem';

function HomePage() {
  const { data } = useQuery('featuredLists', () => {
    return getFeaturedLists();
  });

  const { listIds = [] } = data || {};
  return (
    <Container>
      <Grid container spacing={2}>
        <Box sx={{ width: '100%' }}>
          <Paper
            sx={{
              padding: '2rem 1rem',
              border: theme => `1px solid ${theme.palette.primary.main}`,
              marginTop: '1rem',
              marginBottom: '2rem',
            }}
          >
            <Typography variant="h1">Rank Anything</Typography>
            <Typography variant="subtitle1">
              Especially Nic Cage movies
            </Typography>
          </Paper>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Typography variant="h5">Popular Lists</Typography>
          <Stack
            spacing={1}
            divider={<Divider orientation="horizontal" flexItem />}
          >
            {listIds.map(listId => (
              <FeaturedListItem key={listId} listId={listId} />
            ))}
          </Stack>
        </Box>
      </Grid>
    </Container>
  );
}

export default HomePage;
