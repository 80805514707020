import { useEffect } from 'react';
import { listAtom, listIdsAtom, entryAtom } from './atoms';
import { allListDataQuery } from './selectors';
import { useRecoilValue, useRecoilCallback, useSetRecoilState } from 'recoil';

/**
 * Set up the core application data & state
 * 1) Fetch allListDataQuery
 * 2) initialize atomFamilies & atoms for lists & entries
 * 3) set app loaded value
 */
function RecoilStore({ children = null }) {
  const {
    listIds: listIds,
    listIdMap,
    entryIdMap,
  } = useRecoilValue(allListDataQuery);
  const setListIds = useSetRecoilState(listIdsAtom);

  const createListAtom = useRecoilCallback(({ set }) => (listData: any) => {
    set(listAtom(listData.id), listData);
  });
  const createEntryAtom = useRecoilCallback(({ set }) => (entryData: any) => {
    set(entryAtom(entryData.id), entryData);
  });

  // INITIALIZE DATA STORE
  useEffect(() => {
    if (listIds && listIds.length && listIdMap) {
      // set list ids
      setListIds(
        [...listIds].sort((a, b) => {
          if (listIdMap[a].matchupCount === listIdMap[b].matchupCount) return 0;
          return listIdMap[a].matchupCount < listIdMap[b].matchupCount ? 1 : -1;
        }),
      );

      // set lists
      listIds.forEach(id => createListAtom(listIdMap[id]));

      // set entries
      Object.keys(entryIdMap).forEach(id => createEntryAtom(entryIdMap[id]));
    }
  }, [listIds, listIdMap]);
  return children;
}

export default RecoilStore;
