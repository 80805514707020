import { baseApiURL } from 'config';
import { MatchupPost } from 'types';

export function postMatchup(matchupData: MatchupPost) {
  return fetch(`${baseApiURL}/api/matchups`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(matchupData),
  });
}

// {
// 	"entryA": "06e49685-d52e-4eec-a3cf-0b5fbcd4bfbc",
// 	"entryB": "dbfefbfd-b547-4a8a-9327-962680d32959",
// 	"winner": "06e49685-d52e-4eec-a3cf-0b5fbcd4bfbc",
// 	"loser": "dbfefbfd-b547-4a8a-9327-962680d32959",
// 	"listId": "9d0098ed-d07c-43ff-a3f3-3ce958858b6b",
// 	"userId": "liam"
// }
