import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { fetchImageSuggestion } from 'utils';

function CreateListTile({ title }) {
  const [image, setImage] = useState('');
  const [imageLoading, setImageLoading] = useState(false);
  useEffect(() => {
    setImageLoading(true);
    fetchImageSuggestion(title)
      .then(({ imageUrl }) => {
        setImage(imageUrl);
        setImageLoading(false);
      })
      .catch(() => {
        setImageLoading(false);
      });
  }, []);
  return (
    <Grid item xs={4} sm={3} md={2}>
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Typography variant="body2">{title}</Typography>
          {imageLoading && <CircularProgress />}
          {image && <img style={{ height: '120px' }} src={image} />}
        </CardContent>
      </Card>
    </Grid>
  );
}

export default CreateListTile;
