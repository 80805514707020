import React from 'react';
import GroupIcon from '@mui/icons-material/Group';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Paper, Stack, Typography } from '@mui/material';

type StatIconType = 'entries' | 'matchups' | 'users';
function StatIcon({ type, count }: { type: StatIconType; count: number }) {
  let icon;
  let text;
  if (type === 'entries') {
    icon = <FormatListBulletedIcon />;
    text = `${count} ${count === 1 ? 'entry' : 'entries'}`;
  }
  if (type === 'matchups') {
    icon = <ShowChartIcon />;
    text = `${count} ${count === 1 ? 'matchup' : 'matchups'}`;
  }
  if (type === 'users') {
    icon = <GroupIcon />;
    text = `${count} ${count === 1 ? 'voter' : 'voters'}`;
  }
  return (
    <Paper sx={{ padding: '1rem' }}>
      <Stack alignItems="center" direction="row">
        {icon}
        <Typography variant="overline">{text}</Typography>
      </Stack>
    </Paper>
  );
}

export default StatIcon;
