import { Box, Grid, Typography } from '@mui/material';
import { getUserRankingsForList } from 'api/list';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import React from 'react';
import { selectEntyIdMapForList, selectListIdMap } from 'recoilStore/selectors';

function UserRankingsListItem({ listId, userId }) {
  const navigate = useNavigate();
  const { data: userRankingsData = {} } = useQuery(
    `userRankings-${listId}-${userId}`,
    () => getUserRankingsForList(listId, userId),
  );
  const { matchupCount, rankings = [], records = {} } = userRankingsData;
  const listIdMap = useRecoilValue(selectListIdMap);
  const entryIdMap = useRecoilValue(selectEntyIdMapForList(listId));
  const entryId = rankings[0];
  const entry = entryIdMap[entryId];
  const record = records[entryId];
  return (
    <Grid
      onClick={() => navigate(`/list/${listId}`)}
      item
      key={listId}
      xs={6}
      border={1}
      p={1}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <div>{listIdMap[listId].title}</div>
        {matchupCount && <div>{matchupCount} matchups</div>}
        {entry?.title && <Box>#1: {entry.title}</Box>}
        {record && (
          <Box>
            <Typography sx={{ color: 'success.dark' }} component="span">
              {record.winCount}
            </Typography>{' '}
            -{' '}
            <Typography sx={{ color: 'error.light' }} component="span">
              {record.lossCount}
            </Typography>
          </Box>
        )}
      </div>
      <Box style={{ display: 'flex' }}>
        {entry?.image && (
          <div>
            <img
              style={{
                maxHeight: '250px',
                height: 'auto',
                width: '100%',
                objectFit: 'contain',
              }}
              src={entry.image}
            />
          </div>
        )}
      </Box>
    </Grid>
  );
}

export default UserRankingsListItem;
