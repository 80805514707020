import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { selectEntyIdMapForList, selectQueueData } from 'recoilStore/selectors';
import { matchupQueueIndexAtom } from 'recoilStore/atoms';

import Queue from './Queue';
import Matchup from './Matchup';

function Cage({
  listId,
  showQueue = false,
  isLoading = false,
  refetchExclusions,
}) {
  const { queue: cageQueue, index: matchupIndex } = useRecoilValue(
    selectQueueData(listId),
  );
  // const cageQueue = useRecoilValue(selectMatchupQueue(listId));
  const entryIdMap = useRecoilValue(selectEntyIdMapForList(listId));
  const setMatchupIndex = useSetRecoilState(matchupQueueIndexAtom(listId));

  return (
    <Box sx={{ flexDirection: 'column', display: 'flex' }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Matchup
          setMatchupIndex={setMatchupIndex}
          matchupIndex={matchupIndex}
          entryIdMap={entryIdMap}
          matchupPair={cageQueue[matchupIndex]}
          refetchExclusions={refetchExclusions}
          listId={listId}
        />
      )}
      {showQueue && (
        <Queue
          entryIdMap={entryIdMap}
          queue={cageQueue}
          matchupIndex={matchupIndex}
        />
      )}
    </Box>
  );
}

export default Cage;
