import { baseApiURL } from 'config';

export function postHideEntry(exclusionData: {
  listId: string;
  entryIds: string[];
  userId: number;
}) {
  return fetch(`${baseApiURL}/api/exclusion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(exclusionData),
  });
}

export function postRemoveExclusion(exclusionData: {
  listId: string;
  entryIds: string[];
  userId: number;
}) {
  const { listId, userId, entryIds } = exclusionData;
  const postData = {
    userId,
    toInclude: entryIds,
  };
  return fetch(`${baseApiURL}/api/exclusion/${listId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postData),
  });
}

export function fetchAllExclusions(userId: number) {
  const route = `/api/exclusion/${String(userId)}`;
  return fetch(`${baseApiURL}${route}`).then(r => r.json());
}
