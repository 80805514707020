import { Box, Grid, List, ListItem, Typography } from '@mui/material';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { listAtom, entryAtom } from 'recoilStore/atoms';

function Item({ listId }) {
  const list = useRecoilValue(listAtom(listId));

  const navigate = useNavigate();
  const entryId = list.entries[0];
  const entry = useRecoilValue(entryAtom(entryId));

  const { image: imageUrl } = entry;
  if (!imageUrl) return null;
  return (
    <GridItem
      item
      xs={4}
      md={3}
      key={listId}
      onClick={() => navigate(`/list/${listId}`)}
    >
      <GridItemContent>
        {/* Image */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {imageUrl && <Image src={imageUrl} />}
        </div>
        {/* title & metadata */}
        <div>
          {list.title && (
            <Centered>
              <Typography variant="h5">{list.title}</Typography>
            </Centered>
          )}

          {/* links */}
          <List>
            <ListItem>{list.entries.length} entries</ListItem>
            <ListItem>{list.matchupCount} matchups</ListItem>
            <ListItem>{list.voterCount} users</ListItem>
          </List>
        </div>
      </GridItemContent>
    </GridItem>
  );
}

function Gallery({ listIds }: { listIds: string[] }) {
  return (
    <GalleryContainer>
      <Grid container spacing={2}>
        {listIds.map(listId => (
          <Item key={listId} listId={listId} />
        ))}
      </Grid>
    </GalleryContainer>
  );
}

const GalleryContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 1rem;
`;

const Centered = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const GridItem = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
`;

const GridItemContent = styled.div`
  padding: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Image = styled.img`
  width: 100%;
  height: 270px;
  object-fit: contain;
`;

export default Gallery;
