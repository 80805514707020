import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { listAtom } from 'recoilStore/atoms';
import StatIcon from './StatIcon';

function ListInfoSection({ listId }) {
  const list = useRecoilValue(listAtom(listId));
  return (
    <Stack
      spacing={2}
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h2">{list.title}</Typography>
      <Typography variant="subtitle1">{list.description}</Typography>
      <Stack
        direction="row"
        justifyContent="space-around"
        sx={{ width: '100%' }}
      >
        <StatIcon type="entries" count={list.entries.length} />
        <StatIcon type="matchups" count={list.matchupCount} />
        <StatIcon type="users" count={list.voterCount} />
      </Stack>
    </Stack>
  );
}

export default ListInfoSection;
