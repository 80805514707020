import React from 'react';
import { Box } from '@mui/material';
import { CSSProperties } from '@mui/styled-engine';

function EntryImage({ height = '', width = '', src, style = {}, alt = '' }) {
  const sx = {
    ...style,
  } as CSSProperties;
  if (height) {
    sx.height = height;
  } else if (width) {
    sx.width = width;
  }
  return (
    <Box sx={sx}>
      <img
        alt={alt}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        src={src}
      />
    </Box>
  );
}

export default EntryImage;
