import { Container, Input } from '@mui/material';
import Gallery from 'components/Gallery';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { listIdsAtom } from 'recoilStore/atoms';
import { selectListIdMap } from 'recoilStore/selectors';

function SearchPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const masterListIds = useRecoilValue(listIdsAtom);

  const listIdMap = useRecoilValue(selectListIdMap);
  function filterByQuery(listId) {
    return listIdMap[listId].title.toLowerCase().indexOf(searchQuery) >= 0;
  }
  const listIds = masterListIds.filter(filterByQuery);

  return (
    <Container>
      <Input
        fullWidth
        value={searchQuery}
        placeholder="Search"
        onChange={e => setSearchQuery(e.target.value)}
      />
      <Gallery listIds={listIds} />
    </Container>
  );
}

export default SearchPage;
