import { atom, atomFamily } from 'recoil';
import { Entry, Exclusion, User } from 'types';

// ATOMS
// define an atom or atomFamily for each primary data type

// list metadata
export const listAtom = atomFamily({
  key: 'list',
  default: { entries: [] } as any,
});

// listIds
export const listIdsAtom = atom({
  key: 'listIds',
  default: [] as string[],
});

// entry metadata
export const entryAtom = atomFamily({
  key: 'entry',
  default: {} as Entry,
});

// exclusion by listId
export const exclusionsByListIdAtom = atomFamily({
  key: 'exclusionByList',
  default: [] as Exclusion,
});

// MATCHUPS
// described by a queue of entry-pairs and an index indicating the place in the queue

// matchupQueue
export const matchupQueueAtom = atomFamily({
  key: 'matchupQueue',
  default: [] as string[],
});

export const matchupQueueIndexAtom = atomFamily({
  key: 'matchupQueueIndex',
  default: 0,
});

export const currentUserAtom = atom({
  key: 'currentUser',
  default: {} as User,
});

export const userAtom = atomFamily({
  key: 'user',
  default: {} as User,
});
