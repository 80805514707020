import { Button } from '@mui/material';
import React from 'react';
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';
import GoogleIcon from '@mui/icons-material/Google';

const isDevelopment = process.env.NODE_ENV === 'development';
const prodId =
  '209128140292-hcr78dpvsradm3rqho5e9q5tfcs4luca.apps.googleusercontent.com';
const devId =
  '209128140292-ghmra99081sh7nuosfj3006oruiahhrc.apps.googleusercontent.com';

function SignInWithGoogle({
  onSuccess,
  onFail,
}: {
  onSuccess: (data: any) => void;
  onFail: (data: any) => void;
}) {
  function handleCredentialResponse(response) {
    const data = response as GoogleLoginResponse;
    onSuccess(data);
  }
  function googleFail(data) {
    if (data && data.error === 'popup_closed_by_user') return;
    onFail(data);
  }

  return (
    <GoogleLogin
      // style={{ width: '100%', marginTop: 0 }}
      clientId={isDevelopment ? devId : prodId}
      onSuccess={handleCredentialResponse}
      onFailure={googleFail}
      render={renderProps => (
        <Button
          size="large"
          startIcon={<GoogleIcon />}
          variant="contained"
          onClick={renderProps.onClick}
          style={{ width: '100%', marginTop: 0 }}
        >
          Sign in with Google
        </Button>
      )}
    />
  );
}

export default SignInWithGoogle;
