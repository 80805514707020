import { Box, Button, Divider } from '@mui/material';
import styled from 'styled-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import Cookies from 'js-cookie';
import PersonIcon from '@mui/icons-material/Person';

import { currentUserAtom } from 'recoilStore/atoms';
import { darkModeAtom } from 'recoilStore/ui';
import Logo from './Logo';

function Header() {
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useRecoilState(darkModeAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  return (
    <Box
      onClick={e => e.stopPropagation()}
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: theme => `1px solid ${theme.palette.primary.main}`,
      }}
      mb={1}
      p={1}
      pl={2}
    >
      <Logo />
      <HeaderButtonArea>
        <Button onClick={() => navigate('/about')}>About</Button>
        <Button
          onClick={() => {
            Cookies.set('darkMode', darkMode ? 'false' : 'true');
            setDarkMode(!darkMode);
          }}
        >
          Toggle Dark Mode
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button onClick={() => navigate('/create')}>Create</Button>
        <Button onClick={() => navigate('/search')}>Search</Button>
        {currentUser && currentUser.username ? (
          <Button onClick={() => navigate(`/@/${currentUser.username}`)}>
            <PersonIcon />
          </Button>
        ) : (
          <Button onClick={() => navigate('/login')}>Login</Button>
        )}
      </HeaderButtonArea>
    </Box>
  );
}

const HeaderButtonArea = styled(Box)`
  display: flex;
  justify-content: space-between;
  > div,
  a,
  button {
    padding: 1rem;
  }
`;

export default Header;
