import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  CircularProgress,
  Typography,
  Button,
  Modal,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Paper,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import RankingsGrid from 'components/RankingsGrid';
import Cage from 'components/Cage';
import ListInfoSection from 'components/ListInfoSection';
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  currentUserAtom,
  entryAtom,
  exclusionsByListIdAtom,
  listAtom,
} from 'recoilStore/atoms';
import {
  exclusionMapByListIdAtom,
  selectEntyIdMapForList,
} from 'recoilStore/selectors';
import { useQuery } from 'react-query';
import {
  fetchAllExclusions,
  postHideEntry,
  postRemoveExclusion,
} from 'api/exclusion';
import { AllExclusions } from 'types';
import { loginModalAtom, loginRedirectAtom } from 'recoilStore/ui';
import { getListById } from 'api/list';

function ListPage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { listId = '' } = useParams();
  const { id: userId } = useRecoilValue(currentUserAtom);
  const { isLoading, data: rawListData } = useQuery(
    `fetchList${listId}`,
    () => {
      return getListById(listId);
    },
  );

  // todo - extract this data loading into a util
  const createListAtom = useRecoilCallback(({ set }) => (listData: any) => {
    set(listAtom(listData.id), listData);
  });
  const createEntryAtom = useRecoilCallback(({ set }) => (entryData: any) => {
    set(entryAtom(entryData.id), entryData);
  });
  useEffect(() => {
    if (isLoading) return;
    if (!rawListData) return;
    const { listIds, listIdMap, entryIdMap } = rawListData;

    // set lists
    listIds.forEach(id => createListAtom(listIdMap[id]));

    // set entries
    Object.keys(entryIdMap).forEach(id => createEntryAtom(entryIdMap[id]));
  }, [isLoading, rawListData]);

  const listData = useRecoilValue(listAtom(listId));

  useEffect(() => {
    const isRedirectReady = false;
    if (!isRedirectReady) return;
    if (!listData.entries.length) {
      navigate('/');
    }
  }, [listData]);

  const entryIdMap = useRecoilValue(selectEntyIdMapForList(listId));
  // fetch the exclusions and assign to an atom
  const {
    data,
    isLoading: isLoadingExclusions,
    refetch: refetchAllExclusions,
  } = useQuery('allExclusions', () => fetchAllExclusions(userId));
  const setExclusionsState = useRecoilCallback(
    ({ set }) =>
      (exclusionsData: AllExclusions) => {
        Object.keys(exclusionsData).forEach(exclusionListId => {
          set(
            exclusionsByListIdAtom(exclusionListId),
            exclusionsData[exclusionListId],
          );
        });
      },
  );
  useEffect(() => {
    // whenever we call refetch, data will update and we should re-seed the exclusions atoms for all lists
    if (!isLoadingExclusions) {
      setExclusionsState(data);
    }
  }, [data, isLoadingExclusions]);
  const exclusionMap = useRecoilValue(exclusionMapByListIdAtom(listId));

  // login redirect
  const setLoginRedirect = useSetRecoilState(loginRedirectAtom);

  // MODAL
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] =
    useRecoilState(loginModalAtom);
  const modalStyle = {
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const handleEntryToggle = id => {
    const payload = {
      listId,
      entryIds: [id],
      userId,
    };
    if (exclusionMap[id]) {
      return postRemoveExclusion(payload).then(() => refetchAllExclusions());
    } else {
      return postHideEntry(payload).then(() => refetchAllExclusions());
    }
  };

  if (!listData.entries.length) {
    return null;
  }

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <React.Suspense fallback={<CircularProgress />}>
        <Modal
          open={isLoginModalOpen}
          onClose={() => setIsLoginModalOpen(false)}
        >
          <Box
            sx={
              {
                ...modalStyle,
                top: '30%',
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                height: '150px',
              } as any
            }
          >
            <Typography variant="body1">Log in to start ranking</Typography>
            <Button
              variant="contained"
              onClick={() => {
                setLoginRedirect(pathname);
                navigate('/login');
                setIsLoginModalOpen(false);
              }}
            >
              Log in
            </Button>
          </Box>
        </Modal>
        <Box pb={2} sx={{ width: '100%' }}>
          <ListInfoSection listId={listId} />
        </Box>
        <Box sx={{ width: '100%' }} m={2}>
          <Paper>
            <Cage
              listId={listId}
              isLoading={isLoadingExclusions}
              refetchExclusions={refetchAllExclusions}
            />
          </Paper>
        </Box>
        <div>
          <Button variant="text" onClick={() => setIsListModalOpen(true)}>
            View all entries
          </Button>
          <Modal
            open={isListModalOpen}
            onClose={() => setIsListModalOpen(false)}
            sx={{ overflow: 'scroll' }}
          >
            <Box sx={modalStyle as any}>
              <List>
                {listData.entries.map(id => {
                  // TODO - finish this onChange handler including the callback defs
                  // move the condition up into component
                  const isHidden = exclusionMap[id];
                  return (
                    <ListItem key={id}>
                      <ListItemText>{entryIdMap[id].title}</ListItemText>
                      <ListItemSecondaryAction>
                        <Switch
                          checked={!isHidden}
                          onChange={() => handleEntryToggle(id)}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Modal>
        </div>
        <Box pt={2} pb={2}>
          <Typography variant="h3">Community Rankings</Typography>
        </Box>
        <Box p={'3 0'}>
          <Paper>
            <RankingsGrid listId={listId} />
          </Paper>
        </Box>
      </React.Suspense>
    </Container>
  );
}

export default ListPage;
