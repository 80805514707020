import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilCallback, useRecoilValue } from 'recoil';
import Cookies from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { currentUserAtom, userAtom } from 'recoilStore/atoms';
import { getUser } from 'api/auth';
import { User } from 'types';
import { selectListIdMap } from 'recoilStore/selectors';
import UserRankingsListItem from 'components/UserRankingsListItem';

function UserPage() {
  const { username } = useParams();
  const navigate = useNavigate();
  const listIdMap = useRecoilValue(selectListIdMap);
  console.log(listIdMap);

  const { data: userData, isLoading: isLoadingUser } = useQuery(
    `${username}-query`,
    () => getUser(username),
  );
  const setUserAtom = useRecoilCallback(({ set }) => (u: User) => {
    set(userAtom(u.id), u);
  });
  useEffect(() => {
    if (isLoadingUser) return;
    setUserAtom(userData);
  }, [userData, isLoadingUser]);

  const [currentUser, setCurrentUser] = useRecoilState(currentUserAtom);
  const isCurrentUser = currentUser.username === username;

  function logout() {
    Cookies.remove('authToken');
    setCurrentUser({});
    navigate('/');
  }

  const [showAllActivity, setShowAllActivity] = useState(false);

  // return an array of objects { listId, matchupCount, topRated }
  const sortedListStats = useMemo(() => {
    if (isLoadingUser) return [];
    if (!userData?.listStats) return [];
    const ids = Object.keys(userData.listStats);

    return ids
      .map(id => ({
        listId: id,
        matchupCount: userData.listStats[id].matchup_count,
      }))
      .sort((a, b) => {
        return a.matchupCount === b.matchupCount
          ? 0
          : a.matchupCount > b.matchupCount
          ? -1
          : 1;
      });
  }, [userData]);
  return (
    <Container>
      {isLoadingUser ? (
        <CircularProgress />
      ) : (
        <Grid container>
          <Grid
            item
            xs={12}
            my={3}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="h4">{username}</Typography>
            {isCurrentUser && (
              <Button variant="outlined" onClick={logout}>
                Logout
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant="h2">User Stats</Typography>
              <Typography variant="h3">{`@${username} Rankings`}</Typography>
              <Grid container>
                {sortedListStats.map(({ listId }, index) => {
                  if (index > 5 && !showAllActivity) return null;
                  return (
                    <UserRankingsListItem
                      key={listId}
                      listId={listId}
                      userId={userData.id}
                    />
                  );
                })}
              </Grid>
              <Button
                onClick={() => {
                  setShowAllActivity(!showAllActivity);
                }}
              >
                {showAllActivity ? 'hide' : 'show all'}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant="h2">User Stats</Typography>
            </Box>
          </Grid>
          {(userData.listsCreated || []).length && (
            <>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="h2">Lists created</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="h2">Lists created</Typography>
                </Box>
              </Grid>
            </>
          )}
          <div>{JSON.stringify(userData)}</div>
        </Grid>
      )}
    </Container>
  );
}

export default UserPage;
