import { baseApiURL } from 'config';

export function getUserRankingsForList(listId, userId) {
  const route = `/api/user/${userId}/list/${listId}`;
  return fetch(`${baseApiURL}${route}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(r => r.json());
}

export function postCreateList(data) {
  const route = `/api/lists`;
  return fetch(`${baseApiURL}${route}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(response => {
    if (response.status !== 201) return Promise.reject('Error creating list');
    return response.json();
  });
}

export function getFeaturedLists() {
  const route = '/api/lists/featured';
  return fetch(`${baseApiURL}${route}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(r => r.json());
}

export function getListById(listId) {
  const route = `/api/lists/${listId}`;
  return fetch(`${baseApiURL}${route}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(r => r.json());
}
