import React from 'react';
import { Box, List, ListItem } from '@mui/material';

type Props = {
  queue: any[];
  matchupIndex: number;
  entryIdMap: any;
  length?: number;
};

function Queue({ queue, matchupIndex, entryIdMap, length = 3 }: Props) {
  return (
    <Box>
      <List>
        {queue.map((matchupPair, index) => {
          if (index <= matchupIndex) return null;
          if (index >= matchupIndex + length) return null;
          const leftEntry = entryIdMap[matchupPair[0]];
          const rightEntry = entryIdMap[matchupPair[1]];
          return (
            <ListItem
              key={`${matchupPair[0]}-${matchupPair[1]}`}
              sx={{
                width: '100%',
                height: '60px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div>{leftEntry.title}</div>
                <div>
                  <img style={{ height: '100%' }} src={leftEntry.image} />
                </div>
              </div>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div>{rightEntry.title}</div>
                <div>
                  <img style={{ height: '100%' }} src={rightEntry.image} />
                </div>
              </div>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

export default Queue;
