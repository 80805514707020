import { Button, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { Entry } from 'types';
import EntryImage from './EntryImage';

type MatchupTileProps = {
  entry: Entry;
  selectWinner: () => void;
  hideEntry: () => void;
  isLeftTile?: boolean;
};

function MatchupTile({
  entry,
  selectWinner,
  hideEntry,
  isLeftTile = false,
}: MatchupTileProps) {
  return (
    <Paper
      elevation={3}
      sx={{
        cursor: 'pointer',
        margin: '1rem',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'transparent',
        '&:hover': {
          borderColor: 'primary.main',
        },
        paddingTop: '2rem',
      }}
      onClick={e => {
        e.stopPropagation();
        selectWinner();
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <EntryImage src={entry.image} />

        <div
          style={{
            margin: 'auto',
          }}
        >
          <Typography variant="body1">{entry.title}</Typography>
        </div>
      </div>
      <Stack
        direction="row"
        justifyContent={isLeftTile ? 'flex-end' : 'flex-start'}
        px={1}
      >
        <Button
          onClick={e => {
            e.stopPropagation();
            hideEntry();
          }}
          variant="text"
          color="secondary"
        >
          Hide
        </Button>
      </Stack>
    </Paper>
  );
}

export default MatchupTile;
