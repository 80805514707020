import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { entryAtom, listAtom } from 'recoilStore/atoms';
import EntryImage from './EntryImage';
import StatIcon from './StatIcon';

function FeaturedList({ listId }) {
  const list = useRecoilValue(listAtom(listId));

  const navigate = useNavigate();
  const entryId = list.entries[0];
  const entry = useRecoilValue(entryAtom(entryId));

  const { image: imageUrl } = entry;
  return (
    <Grid
      component={Paper}
      item
      container
      xs={12}
      py={2}
      sx={{ border: theme => `1px solid ${theme.palette.secondary.main}` }}
    >
      <Grid item xs={3}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <EntryImage src={imageUrl} height="180px" />
        </div>
      </Grid>
      <Grid item xs={9}>
        <Stack spacing={1} alignItems={'center'}>
          <div style={{ display: 'inline-block' }}>
            <Typography
              // onClick={() => navigate(`/list/${listId}`)}
              variant="h3"
            >
              {list.title}
            </Typography>
          </div>
          <Stack
            direction="row"
            justifyContent="space-around"
            sx={{ width: '100%' }}
          >
            <StatIcon type="entries" count={list.entries.length} />
            <StatIcon type="matchups" count={list.matchupCount} />
            <StatIcon type="users" count={list.voterCount} />
          </Stack>
          <Box>
            <Button
              variant="contained"
              onClick={() => navigate(`/list/${listId}`)}
              size="large"
            >
              Start Ranking
            </Button>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default FeaturedList;
