import { Container, Grid, Typography } from '@mui/material';
import React from 'react';

function AboutPage() {
  return (
    <Container>
      <Grid container>
        <Grid item>
          <Typography variant="h1">Rank anything</Typography>
          <Typography variant="subtitle1">
            Especially Nic Cage movies
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AboutPage;
