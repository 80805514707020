import React from 'react';
import MatchupTile from './MatchupTile';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { postMatchup } from 'api/matchup';
import { postHideEntry } from 'api/exclusion';
import { entryAtom, listAtom, currentUserAtom } from 'recoilStore/atoms';
import { loginModalAtom } from 'recoilStore/ui';
import { findDiff } from 'utils';
import { Box, Button, Stack, Typography } from '@mui/material';

type Props = {
  matchupIndex: number;
  entryIdMap: any;
  setMatchupIndex: (x: number) => void;
  matchupPair: [string, string];
  listId: string;
  refetchExclusions: () => void;
};

function Matchup({
  matchupIndex,
  entryIdMap,
  setMatchupIndex,
  matchupPair,
  listId,
  refetchExclusions,
}: Props) {
  const currentUser = useRecoilValue(currentUserAtom);
  const { id: userId } = currentUser;
  const hideEntry = useRecoilCallback(() => entryId => {
    return postHideEntry({
      listId,
      entryIds: [entryId as string],
      userId,
    }).then(() => {
      refetchExclusions();
    });
  });

  // click handler, this should make an API post and update all local state
  const submitMatchup = useRecoilCallback(
    ({ set }) =>
      winnerId => {
        let winner = matchupPair[1];
        let loser = matchupPair[0];

        if (!userId) {
          set(loginModalAtom, true);
          return;
        }
        if (winnerId === matchupPair[0]) {
          winner = matchupPair[0];
          loser = matchupPair[1];
        }
        const matchupData = {
          winner,
          loser,
          entryA: matchupPair[0],
          entryB: matchupPair[1],
          listId,
          userId: String(userId),
        };

        // incrementCount(listId);
        return postMatchup(matchupData).then(() => {
          const { winner, loser } = matchupData;
          // update the winner and loser entry atoms
          // update score, winCount, lossCount
          const winnerEntry = entryIdMap[winner];
          const loserEntry = entryIdMap[loser];
          const [winnerDiff, loserDiff] = findDiff(
            winnerEntry.score,
            loserEntry.score,
          );

          set(entryAtom(winner), winnerOldState => ({
            ...winnerOldState,
            winCount: winnerOldState.winCount + 1,
            score: winnerOldState.score + winnerDiff,
          }));
          set(entryAtom(loser), loserOldState => ({
            ...loserOldState,
            lossCount: loserOldState.lossCount + 1,
            score: loserOldState.score + loserDiff, // loserDiff will be negative
          }));

          const isNewVoter = !currentUser.listStats?.[listId]?.matchup_count;

          set(listAtom(listId), listOldState => ({
            ...listOldState,
            matchupCount: listOldState.matchupCount + 1,
            voterCount: isNewVoter
              ? listOldState.voterCount + 1
              : listOldState.voterCount,
          }));
          const userMatchupCount =
            currentUser.listStats?.[listId]?.matchup_count;
          const newCurrentUser = {
            ...currentUser,
            listStats: {
              ...currentUser.listStats,
              [listId]: {
                matchup_count: (userMatchupCount || 0) + 1,
              },
            },
          };
          set(currentUserAtom, newCurrentUser);
          setMatchupIndex(matchupIndex + 1);
          // todo- refetch other data as needed, possibly refetch rankings for a user or user-group
        });
      },
    [currentUser, matchupIndex],
  );

  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent="center" alignItems="stretch">
        <Box
          sx={{
            width: '45%',
          }}
        >
          <MatchupTile
            hideEntry={() => hideEntry(matchupPair[0])}
            selectWinner={() => submitMatchup(matchupPair[0])}
            entry={entryIdMap[matchupPair[0]]}
            isLeftTile
          />
        </Box>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          style={{ height: 'auto', padding: '1rem 0' }}
        >
          <Box height="30%"></Box>
          <Typography variant="overline" alignSelf="center">
            vs
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setMatchupIndex(matchupIndex + 1);
              }}
              color="secondary"
            >
              Skip
            </Button>
          </div>
        </Stack>
        <Box
          sx={{
            width: '45%',
          }}
        >
          <MatchupTile
            hideEntry={() => hideEntry(matchupPair[1])}
            selectWinner={() => submitMatchup(matchupPair[1])}
            entry={entryIdMap[matchupPair[1]]}
          />
        </Box>
      </Stack>
    </Stack>
  );
}

export default Matchup;
