import {
  Button,
  Container,
  Grid,
  IconButton,
  Input,
  InputLabel,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { postUpdatePassword } from 'api/auth';
import { useSetRecoilState } from 'recoil';
import { currentUserAtom } from 'recoilStore/atoms';
import { User } from 'types';

function PasswordResetPage() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { token } = qs.parse(search, { ignoreQueryPrefix: true });
  const setCurrentUser = useSetRecoilState(currentUserAtom);

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const updatePassword = useCallback(() => {
    return postUpdatePassword({ token, password }).then(user => {
      setCurrentUser(user as User);
      navigate('/');
    });
  }, [token, password]);

  const isValidPassword = password.length > 3;

  return (
    <Container>
      <Grid
        sx={{
          width: '300px',
        }}
        container
        rowSpacing={2}
        mx="auto"
      >
        <Grid item xs={12}>
          <InputLabel>
            Create a new password
            <IconButton
              tabIndex={-1}
              onClick={e => {
                e.preventDefault();
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
            </IconButton>
          </InputLabel>
          <Input
            sx={{ width: '100%' }}
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            onClick={updatePassword}
            disabled={!isValidPassword}
          >
            Update Password
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PasswordResetPage;
